.promo-card {

    [data-bs-theme=dark] {
        color: white;
    }

    scale: inherit;
    box-shadow: inherit;
    cursor: pointer;
    filter: grayscale(1);
    color: black;

    .promo-text-bg {
        opacity: 0.65;
        transition: all 200ms ease-in-out;
    }

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 1rem 3rem rgba(#000, .175);
        filter: grayscale(0.420);

        .promo-text-bg {
            opacity: 0.9;
        }
    }

    transition: all 300ms ease-in-out;

}
